import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'

import _ from 'lodash'

import './Sidebar.scss'

// Components
import { TitleAlt, ButtonLineDefault } from 'components/Elements'

const Sidebar = () => {
  const { sidebar } = useStaticQuery(
    graphql`
      {
        sidebar: wordpressWpComponents(wordpress_id: { eq: 118 }) {
          title
          acf {
            sidebar_content {
              title_sidebar
              sub_title_sidebar
              button_text_sidebar
            }
          }
        }
      }
    `
  )
  return (
    <div className="sidebar">
      <div className="inner">
        <TitleAlt className="color-text-light">
          <span>{sidebar.acf.sidebar_content.sub_title_sidebar}</span>
          <br />
          <strong>{sidebar.acf.sidebar_content.title_sidebar}</strong>
        </TitleAlt>
        <ButtonLineDefault to="/contact">
          {sidebar.acf.sidebar_content.button_text_sidebar}
        </ButtonLineDefault>
      </div>
      <div className="more">
        <TitleAlt>Meer diensten</TitleAlt>
        <nav className="menu">
          <ul>
            <li>
              <Link to="/diensten/schilderwerk/" className="box">Schilderwerk</Link>
            </li>
            <li>
              <Link to="/diensten/behangwerk/" className="box">Behangwerk</Link>
            </li>
            <li>
              <Link to="/diensten/glaswerk/" className="box">Glaswerk</Link>
            </li>
            <li>
              <Link to="/diensten/onderhoud-en-renovatie/" className="box">Onderhoud &amp; Renovatie</Link>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  )
}

export default Sidebar
