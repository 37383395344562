import React from 'react'
import { graphql } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import { Breadcrumb } from 'gatsby-plugin-breadcrumb'

// Components
import {
  TitleDefault,
  BannerDefault,
  ContentDefault,
  BreadCrumbBar,
} from 'components/Elements'
import SEO from 'components/SEO'
import Layout from 'components/Layout'
import Sidebar from 'components/Sidebar'

import 'styles/service.scss'

export const pageQuery = graphql`
  query($wordpress_id: Int!) {
    page: wordpressWpDiensten(wordpress_id: { eq: $wordpress_id }) {
      title
      acf {
        content_group_dienst {
          content_dienst
          header_image_dienst {
            localFile {
              childImageSharp {
                fluid(quality: 100, maxWidth: 2000) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          title_dienst
        }
      }
      yoast_meta {
        name
        content
        property
      }
    }
  }
`

const ServiceTemplate = ({
  pageContext,
  data: {
    page: {
      title,
      acf: {
        content_group_dienst: {
          title_dienst,
          header_image_dienst,
          content_dienst,
        },
      },
      yoast_meta,
    },
  },
}) => {
  return (
    <Layout>
      <SEO yoast={{ meta: yoast_meta }} />
      <div className="post-template">
        <BannerDefault className="banner-detail">
          <div className="row h-100">
            <div className="image-container">
              <BackgroundImage
                fluid={header_image_dienst.localFile.childImageSharp.fluid}
              />
            </div>
            <div className="container">
              <h1>
                <span dangerouslySetInnerHTML={{ __html: title }} />
              </h1>
            </div>
          </div>
        </BannerDefault>
        <BreadCrumbBar>
          <div className="row m-0">
            <Breadcrumb crumbs={pageContext.breadcrumb.crumbs} crumbSeparator="-" crumbLabel={title} />
          </div>
        </BreadCrumbBar>
        <section className="content-row">
          <div className="container">
            <div className="row">
              <div className="col-lg-7 pt-130">
                <TitleDefault>
                  <span dangerouslySetInnerHTML={{ __html: title_dienst }} />
                </TitleDefault>
                <ContentDefault>
                  <div dangerouslySetInnerHTML={{ __html: content_dienst }} />
                </ContentDefault>
              </div>
              <div className="col-lg-1" />
              <div className="col-lg-4 p-0 mt-lg-0 mt-5">
                <Sidebar />
              </div>
            </div>
          </div>
          <div className="empty-space-100" />
        </section>
      </div>
    </Layout>
  )
}

export default ServiceTemplate
